.packages-container {
  font-family: Krona One;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 25px;
}
.all-packages {
  display: flex;
  gap: 10px;
}
.button-container {
  display: flex;
  gap: 25px;
  justify-content: center;
}
.button-sub-container {
  display: flex;
  width: 50%;
  gap: 10px;
}
.custom-button {
  color: #ffe500;
  background-color: transparent;
  border: 1px solid #ffe500;
}

.pay-button:hover {
  background-color: #ffe500 !important;
}

@media screen and (max-width: 600px) {
  .all-packages {
    display: grid;
    grid-template-columns: 1fr;
    padding: 20px;
  }
  .button-sub-container {
    width: 100%;
  }
  .image-container {
    display: none;
  }
  .tab-container {
    width: 100%;
  }
}

@media (min-width: 600px) and (max-width: 1080px) {
  .all-packages {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 20px;
  }
  .button-sub-container {
    width: 100%;
  }
  .image-container {
    display: none;
  }
  .tab-container {
    width: 100%;
  }
}
