.founder .selected {
  scale: 1.15;
  transition: all 1s;
}

.founder .selected .image {
  border: 1px solid #ffe500;
  padding: 10px;
}

.founder .selected .image div {
  min-width: 202px;
}

.founder .selected .s {
  display: flex;
}

.founder .selected .ns {
  display: none;
}

.founder .slide {
  display: flex;
  align-items: center;
}

.s {
  display: none;
}

.toolHover:hover .expand {
  rotate: 180deg;
}

.discordIcon:hover .discord {
  display: block;
}

.discordIcon:hover {
  max-width: 300px;
  animation: bounce 1.5s ease-in-out;
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translate(0px, 0px);
  }

  30% {
    gap: 5px;
    transform: scale(0.9, 1) translate(0px, 0px);
  }

  75% {
    gap: 15px;
    transform: scale(1.1, 1) translate(0px, 0px);
  }

  100% {
    gap: 11px;
    transform: scale(1, 1) translate(0px, 0px);
  }
}

.discordIcon {
  transition: all 1s ease-out;
}

/* .t1:hover {
  display: none;
}

.t1:hover ~ .t1Alt {
  display: flex;
} */
/* .t2:hover {
  display: none;
}

.t2:hover ~ .t2Alt {
  display: flex;
}
.t3:hover {
  display: none;
}

.t3:hover ~ .t3Alt {
  display: flex;
}
.t4:hover {
  display: none;
}

.t4:hover ~ .t4Alt {
  display: flex;
}
.t5:hover {
  display: none;
}

.t5:hover ~ .t5Alt {
  display: flex;
}
.t6:hover {
  display: none;
}

.t6:hover ~ .t6Alt {
  display: flex;
}
.t7:hover {
  display: none;
}

.t7:hover ~ .t7Alt {
  display: flex;
} */
.icon {
  fill: white;
  background-color: #262626;
  padding: 6px;
  border-radius: 6px;
  min-width: max-content;
}

.icon:hover {
  scale: 1.5;
  transition: all 1s;
  background: #ffe500;
  box-shadow: 1px 1px 2px rgba(255, 229, 0, 0.5),
    -1px -1px 5px rgba(255, 229, 0, 0.5);
  padding: 3px;
  border-radius: 6px;
  fill: #110f0f;
}

.primary_color {
  color: #FFE500;
}

.nameShadow {
  text-shadow: 0px -1px 5.800000190734863px 0px rgba(255, 229, 0, 1);
  text-shadow: 0 0 13.800000190734863px rgba(255, 229, 0, 1);
  color: #FFE500;
  font-family: Krona One;
  font-size: 35px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: center;
  line-height: 0px;
  margin-top: -50px;

}

@media screen and (max-width:768px) {

  .nameShadow {
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
  }
}

.space_160 {
  height: 160px;
}

@media screen and (max-width:768px) {
  .space_160 {
    height: 120px;
  }
}

.Ybg_carrer {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  left: -50%;
  z-index: -1;
}

@media screen and (max-width:768px) {
  .Ybg_carrer {
    scale: 2;
  }

}


.hidden {
  overflow: hidden;
}

.df {
  display: flex;
  justify-content: center;
  align-items: center;
}


.df_btn_col {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 24px;
}