.login-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 14px;
  text-align: start;
  padding: 20px 0px;
}
.sub-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.text-field fieldset {
  border-color: '#FFFFFF';
  background-color: transparent;
}

.input-group {
}

.input-group label {
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.password-group {
  position: relative;
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.forgot-password {
  color: #007bff;
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #ffe500 !important;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
}

.grid-container .MuiInputBase-root {
  width: 100%;
}
