//cards
.card1 {
  background: #262626;
  padding: 77px 20px 36px 29px;
  border-radius: 23.418px;
  transition: all 0.5s;
  // border: 1px solid red;

  &.a2 {
    border-radius: 50%;
    display: flex;
    align-items: end;
    padding: 20px 20px 60px;

    @media screen and (max-width: 768px) {
      padding: 20px 20px 40px;
    }
  }

  &:hover {
    background: #3d3d3d;

    .card1_img {
      transform: rotate(-20deg);
    }

    .card_title {
      color: #ffe500;
      transition: all 0.5s;
    }
  }

  .card1_img {
    position: absolute;
    top: -45px;
    left: -40px;
    transition: all 0.5s;

    &.a2img {
      top: -40px;
      left: 28px;
    }
  }
}

.card2 {
  background: #262626;
  padding: 36px 20px 37px 36px;
  border-radius: 23.418px;
  transition: all 0.5s;
  margin-top: 10px;
  // border: 1px solid red;

  &.a2 {
    border-radius: 50%;
    display: flex;
    align-items: end;
    padding: 20px 20px 60px;

    @media screen and (max-width: 768px) {
      padding: 20px 20px 40px;
    }
  }

  &:hover {
    background: #3d3d3d;

    .card2_img {
      left: -190px;
      transform: rotate(-8deg);

      &.a2img {
        left: -30px;
        // right: -31px;
        transform: scale(1.2);
      }
    }

    .card_title {
      color: #ffe500;
      transition: all 0.5s;
    }
  }

  .card2_img {
    position: absolute;
    top: -58px;
    left: -229px;
    transform: rotate(-4deg);
    transition: all 0.5s;

    &.a2img {
      top: 0px;
      left: -14px;
      // transform: scale(1.2);
      width: 250px;

      @media screen and (max-width: 1200px) {
        top: -4px;
        left: -38px;
      }

      @media screen and (max-width: 768px) {
        top: -9px;
        left: -57px;
        transform: scale(0.9);
      }
    }

    @media screen and (max-width: 768px) {
      width: 320px;
      top: -45px;
      left: -143px;
    }
  }
}

.card3 {
  background: #262626;
  padding: 50px 100px 20px 32px;
  border-radius: 23.418px;
  transition: all 0.5s;
  // border: 1px solid red;

  &.a2 {
    border-radius: 50%;
    display: flex;
    align-items: end;
    padding: 20px 20px 60px;

    @media screen and (max-width: 768px) {
      padding: 20px 20px 40px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 50px 40px 20px 32px;
  }

  &:hover {
    background: #3d3d3d;

    .card3_img {
      right: -9px;
      // transform: rotate(8deg);

      &.a2img {
        left: 31px;
      }
    }

    .card_title {
      color: #ffe500;
      transition: all 0.5s;
    }
  }

  .card3_img {
    position: absolute;
    top: -84px;
    right: -29px;
    transition: all 0.5s;

    &.a2img {
      top: -73px;
      left: 11px;

      @media screen and (max-width: 1120px) {
        top: -89px;
        left: 3px;
      }

      @media screen and (max-width: 768px) {
        top: -60px;
        left: -5px;
        transform: scale(0.7);
      }
    }

    @media screen and (max-width: 768px) {
      width: 160px;
    }
  }
}

.card4 {
  background: #262626;
  padding: 20px 20px 20px 29px;
  border-radius: 23.418px;
  margin-top: 10px;

  // border: 1px solid red;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }

  &.a2 {
    border-radius: 50%;
    display: flex;
    align-items: end;
    padding: 20px 20px 50px;

    @media screen and (max-width: 768px) {
      padding: 20px 20px 25px;
    }
  }

  &:hover {
    background: #3d3d3d;

    .card4_img {
      top: -110px;

      &.a2img {
        top: -110px;
      }
    }

    .card_title {
      color: #ffe500;
      transition: all 0.5s;
    }
  }

  .card4_img {
    position: absolute;
    top: -130px;
    right: -27px;
    transition: all 0.5s;
    scale: 0.8;
    border-radius: 50%;

    &.a2img {
      top: -96px;
      left: 8px;

      @media screen and (max-width: 1120px) {
        top: -127px;
        left: -17px;
      }

      @media screen and (max-width: 768px) {
        top: -96px;
        left: -9px;
        transform: scale(0.9);
      }
    }

    @media screen and (max-width: 768px) {
      width: 150px;
      top: -96px;
    }
  }
}

.hamBurgerv2 {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;

  @media screen and (max-width: 900px) {
    display: block;
  }
}

.hamBurgerv3 {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40px;
  left: 12px;
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
}

//roadmap
.roadmapComp {
  // transform: translateX(80%);
  // margin-left: 300px;
  // border: 1px solid green;
  // z-index: 99;
}

#roadmap {
  // border: 1px solid red;
}

.scaleFitScreen {
  --scale: 0.6;
  transform: scale(var(--scale));
  transform-origin: top center;
  margin-bottom: calc((var(--scale) - 0.85) * 100%);
}

.scaleFitScreenV2 {
  --scale: 0.7;
  transform: scale(var(--scale));
  transform-origin: top center;
  margin-bottom: calc((var(--scale) - 0.85) * 100%);
}

.scaleFitScreenV3 {
  --scale: 0.5;
  transform: scale(var(--scale));
  transform-origin: top center;
  margin-bottom: calc((var(--scale) - 0.85) * 100%);
}

.df_g8 {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 8px;
}

.df_g24_col {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.b1 {
  border: 1px solid red;
}

.buttonFlow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #ffe500;
  z-index: 1;

  span {
    z-index: 2;
  }

  &::before {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 34px;
    content: '';
    background-color: #ffe500;
    position: absolute;
    left: 0;
    top: 0;
    // transform-origin: 50% 100%;
    transform: translateX(-100%);
    transition: transform 0.3s;
    z-index: 0;
  }

  &:hover {
    transition: all 0.2s;
    color: #000000 !important;

    &::before {
      transform: translateX(0%);
    }

    .rightArrow {
      opacity: 1;
      transform: translateX(10px);
    }
  }

  .rightArrow {
    opacity: 0;
    transition: all 0.3s;
  }
}

.desk_hide {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
}

.mob_hide {
  display: block;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

#trigger_roadmap {
  margin-top: 200px;

  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
}

.animate_roadmap {
  transform: translate(100%, -180%);
  position: relative;

  @media screen and (max-width: 768px) {
    transform: translate(0%, 0%);
    opacity: 1;
  }
}

.after_element {
  background: linear-gradient(180deg, rgba(17, 15, 15, 0) 0%, #110f0f 57.88%);
  bottom: 0;
  left: 0;
  position: absolute;
  height: 0%;
  width: 100%;
}

.anime_wrapper {
  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }
}

.custom_disabled {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.css-16zzeau-MuiTypography-root {
  font-size: 16px !important;

  @media screen and (max-width: 768px) {
    font-size: 8px !important;
  }
}

// .tabs_after {
//     position: relative;

//     &::before {
//         content: '';
//         position: absolute;
//         height: 70%;
//         width: 8px;
//         background-color: white;
//         top: 15%;
//         // transform: translateY(-50%);
//         left: -32px;
//         opacity: 0;

//         @media screen and (max-width:768px) {
//             height: 2px;
//             width: 70%;
//             top: 4px;
//             transform: translateX(-50%);
//             left: 50%;

//         }

//     }

//     // &::after {
//     //     content: '';
//     //     position: absolute;
//     //     height: var(--heightP);
//     //     width: 8px;
//     //     background-color: #FFE500;
//     //     top: 70px;
//     //     // transform: translateY(-50%);
//     //     left: -32px;

//     //     @media screen and (max-width:768px) {
//     //         height: 8px;
//     //         width: var(--widthP);
//     //         top: -24px;
//     //         transform: translateX(-50%);
//     //         left: 50%;
//     //         display: none;

//     //     }

//     // }
// }

.tabs_after {
  li {
    opacity: 0;
  }
}

.content {
  opacity: 0;
  background-color: #110f0f;
}

.progressB {
  position: absolute;
  height: 0%;
  width: 8px;
  background-color: white;
  top: 15%;
  // transform: translateY(-50%);
  left: -32px;
}

.progressB2 {
  position: absolute;
  height: 2px;
  background-color: white;
  width: 70%;
  top: 4px;
  transform: translateX(-50%);
  left: 50%;
}

.polarize_v4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;

  img {
    height: 250px;
    width: 250px;

    @media screen and (max-width: 768px) {
      height: 94px;
      width: 94px;
    }
  }
}

//footer
.footer-link-v2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;

  @media screen and (max-width: 900px) {
    justify-content: center;
  }
}

ul,
ol {
  li {
    opacity: 0.6;
  }
}

.order-wrap {
  padding-left: 30px;

  ul,
  ol {
    display: flex;
    flex-direction: column;
    gap: 15px;

    li {
      line-height: 28px;
    }
  }
}

.MuiTabPanel-root {
  padding: 0;
}
.MuiButtonBase-root {
  &:hover {
    background-color: transparent;
  }
}
.MuiPaper-root {
  background-color: #3d3d3d;
  box-shadow: none;
}
