.card-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  gap: 20px;
  border-radius: 20px;
  border: 1px solid grey;
  cursor: pointer;
}

.card-description {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 10px;
  text-align: start;
}
