.psh {
    padding: 60px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;

    @media screen and (max-width:768px) {
        padding: 60px 20px;
        gap: 12px;
    }

    &_subtitle {
        font-family: Krona One;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-align: left;

        @media screen and (max-width:768px) {
            font-size: 12px;
            line-height: 16px;
        }

        span {
            color: #FFE500;
        }

    }

    &_title {
        font-family: Krona One;
        font-size: 35px;
        font-weight: 400;
        line-height: 46px;
        letter-spacing: 0.02em;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-transform: uppercase;

        @media screen and (max-width:768px) {
            font-size: 16px;
            line-height: 24px;
        }

        span {
            color: #FFE500;
        }

    }

    &_desc {
        font-family: Poppins;
        font-size: 22px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.02em;
        text-align: center;
        color: #ffffff99;
        max-width: 973px;

        @media screen and (max-width:768px) {
            font-size: 12px;
            line-height: 18px;
        }

    }
}