.ps_cs {
    padding: 40px 100px 100px;

    @media screen and (max-width:768px) {
        padding: 40px 20px 60px;
    }

    &_wrap {
        padding: 44px 40px;
        border-radius: 30px;
        background-color: #262626;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 50px;
        position: relative;

        @media screen and (max-width:768px) {
            gap: 20px;
            padding: 40px 20px;
        }

        &_img {
            height: 35px;
        }

        &_box {
            width: 100%;
            display: flex;
            gap: 30px;

            @media screen and (max-width:768px) {
                flex-direction: column;
            }

            &_left {
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 24px;

                @media screen and (max-width:768px) {
                    width: 100%;
                }

                &_title {
                    font-family: Poppins;
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 60px;
                    letter-spacing: 0.02em;
                    text-align: left;
                    color: #FFE500;
                }

                &_counter {
                    max-width: 500px;

                    &_timer {
                        display: flex;
                        justify-content: space-between;

                        ul {
                            list-style: none;
                        }

                        .number {
                            font-family: Poppins;
                            font-size: 24px;
                            font-weight: 700;
                            line-height: 35px;
                            letter-spacing: 0.02em;
                            text-align: left;

                        }

                        .text {
                            font-family: Poppins;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            letter-spacing: 0px;
                            text-align: center;

                        }
                    }

                    &_progress {
                        height: 8px;
                        background-color: #3d3d3d;
                        width: 100%;
                        position: relative;
                        margin-top: 24px;

                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            height: 8px;
                            background-color: #ffe500;
                            width: var(--percentage);


                        }
                    }
                }

                &_data {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;

                    &_box {
                        display: flex;
                        gap: 32px;

                        .name {
                            font-family: Krona One;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 20px;
                            letter-spacing: 0px;
                            text-align: center;

                            @media screen and (max-width:768px) {
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }

                        .value {
                            font-family: Poppins;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            letter-spacing: 0px;
                            text-align: left;

                            @media screen and (max-width:768px) {
                                font-size: 12px;
                                line-height: 16px;
                            }


                        }
                    }
                }
            }

            &_right {
                width: 50%;

                @media screen and (max-width:768px) {
                    width: 100%;
                }

                &_buttons {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    gap: 12px;

                    @media screen and (max-width:768px) {
                        grid-template-columns: 1fr 1fr;
                    }



                    input[type="radio"] {
                        display: none;
                    }

                    input[type="radio"]:checked+label {
                        background-color: #ffe500;
                        color: black;
                        transition: 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
                    }


                    label {
                        // width: 134.26px;
                        height: 63.6px;
                        padding: 21.2px, 19.43px, 21.2px, 19.43px;
                        border-radius: 13.25px;
                        border: 0.88px solid #ffffff33;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 12.37px;
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        cursor: pointer;

                        @media screen and (max-width:768px) {
                            padding: 10px;
                        }
                    }
                }

                &_inputs {
                    margin-top: 33px;
                    display: flex;
                    flex-direction: column;
                    gap: 24px;

                    &_inputBox {


                        .label {
                            font-family: Krona One;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 15px;
                            letter-spacing: 0px;
                            text-align: left;
                            margin-bottom: 12px;
                        }

                        input {
                            width: 100%;
                            height: 60px;
                            padding: 24px 22px 24px 22px;
                            border-radius: 15px;
                            border: 1px solid rgba(255, 255, 255, 0.2);
                            background-color: transparent;
                            outline: none;
                            color: white;
                            font-family: Poppins;
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 21px;
                            letter-spacing: 0px;
                            text-align: left;


                            &:focus {
                                transition: 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
                                border-color: #FFE500;
                            }

                        }
                    }
                }
            }
        }

        &_button {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &_icon {
                font-size: 20px;
            }

            button {
                opacity: 0.5 !important;
                pointer-events: none !important;
            }

        }

    }


}