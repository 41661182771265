.retail-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-content: center;
  min-height: 100vh;
  align-items: center;
  text-align: center;
  font-family: Krona One;
}
.image-container {
}

.image-items {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.tab-container {
  width: 90%;
}

@media screen and (max-width: 768px) {
  .retail-container {
    display: grid;
    grid-template-columns: 1fr;
    padding: 20px;
  }
  .image-container {
    display: none;
  }
  .tab-container {
    width: 100%;
  }
}
