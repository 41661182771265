.forgot-container {
  display: flex;
  flex-direction: column;
  text-align: center !important;
  align-items: center;
  gap: 24px;
  width: 60%;
  font-size: 14px;
  text-align: start;
  padding: 20px 0px;
}
.forgot-sub-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.sub-container {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 12px;
}
.otp-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
}
.code-text-field {
  width: '100px';
}
.code-text-field input {
  text-align: center;
}
