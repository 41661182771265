.dialog-sub-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 800px;
}

.dropzone {
  min-height: 140px;
  border: 1px dashed #ffe500;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 20px;
  border-radius: 10px;
}
