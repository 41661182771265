.dialog-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 14px;
  text-align: start;
  padding: 20px 0px;
}
.dialog-sub-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.text-field {
  min-width: 800px;
}
.submit-button:hover {
  background-color: #ffe500 !important;
}

@media screen and (max-width: 600px) {
  .text-field {
    min-width: 450px;
  }
}

@media (min-width: 600px) and (max-width: 1080px) {
  .text-field {
    min-width: 600px;
  }
}
